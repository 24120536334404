// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

$Color_66:#666666;
$Color_a3:#a3a3a3;
$Hover_color:#ff6900;

.swiper-prev{
    width: 3rem;
    height: 3rem;
    &::after{
        width: 3rem;
        height: 3rem;
        background: url(../img/prev.png) no-repeat center center /100%;
        content:'';
    }
}
.swiper-next{
    width: 3rem;
    height: 3rem;
    &:after{
        width: 3rem;
        height: 3rem;
        background: url(../img/next.png) no-repeat center center /100%;
        content:''
    }
}


// 头部
.navbar{
    background: #ffffff;
}
.navbar-dark {
    .navbar-brand {
      color: $navbar-dark-brand-color;
  
      @include hover-focus {
        color: $navbar-dark-brand-hover-color;
      }
    }
    .navbar-nav {
      .nav-link {
        color:#434343;
  
        @include hover-focus {
          color: #434343;
        }
  
      }
  
      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $Hover_color;
      }
    }
    .navbar-toggler{
        // color:rgba(255, 149, 1, 0.5)
        svg path{
            fill: #ff9501;
        }
    }
}
nav[data-overlay].navbar-toggled-show{
    background: $white;
    &.navbar-dark{
      background: theme-color('primary-3');
      .dropdown-item{
        color: rgba(#434343,.5);
        @include hover-focus{
          color: $Hover_color;
        }
      }
    }
}

.navMenu{
    display: flex;
    align-items: center;
    padding-left: 20%;
    a{
        padding-left:14px;
        display: flex;
        align-items: center;
        transition: all .3s;
        color:#434343;
        &:nth-of-type(2){
            background: url(../img/menu-icon.png) no-repeat left center;
        }
        @include hover-focus{
            color:$Hover_color;
        }
    }
    .on{
        width: 19px;
        height: 19px;
        background: url(../img/icon.png) no-repeat 0 0;
        padding: 0;
        margin-right: 14px;
        @include hover-focus{
            background: url(../img/icon.png) no-repeat -19px 0;
        }
    }
}


#footer{
    width: 100%;
    background: #383838;
    dl{
        float: left;
        margin-right: 6rem;
        dt{
            font-size: .85rem;
            color:#ffffff;
            padding-bottom: .8rem;
        }
        dd{
            font-size: .8rem;
            color:#707070;
            a{
                transition: all .3s;
                color:#707070;
                &:hover{
                    color:#ffffff;
                }
            }
        }
    }
    .WeChat{
        width: 45px;
        position: relative;
        .wxsode{
            width: 0;
            height: 154px;
            background:  url(../img/WeChat-icon.png) no-repeat center center;;
            position: absolute;
            top: 0;
            right: 53px;
            padding: 11px 12px 0 12px;
            border-radius: 8px;
            transition: all .3s;
            opacity: 0;
            overflow: hidden;
            &-con{
                width: 245px;
                display: flex;
                justify-content: space-between;
            }
            .item{
                width: 116px;
                img{
                    width: 100%;
                }
                p{
                    font-size: 13px;
                    color:$Color_66;
                    line-height: 26px;
                    text-align: center;
                }
            }
        }
    }
    .footer-bot{
        .row{
            border-top: 1px #a2a19e solid;
            margin-top: 1.5rem;
            h3,p{
                font-size: .8rem;
                color:#c0c0c0;
            }
        }
    }
}


// svg模板
.skill-mask{
    width: 100%;
    position: relative;
    z-index: 0;
    &-img{
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        img{
            width: 100%;
        }
    }
    &-con{
        -webkit-mask: url(../img/svg/skill-mask.svg) center/contain no-repeat;
        mask: url(../img/svg/skill-mask.svg) center/contain no-repeat;
        position: relative;
        &::before{
            content: '';
            display: block;
            padding-bottom: 100%;
            
        }
    }
}

.title{
    width: 100%;
    text-align: center;
    h3{
        color:$Color_66;
    }
    h4{
        color:$Color_a3;
        font-weight: 500;
    }
}
// 首页
.banner{
    .swiper-button-prev{
        @extend .swiper-prev;
        &::after{
            background: url(../img/banner-prev.png) no-repeat center center /100%;
        }
    }
    .swiper-button-next{
        @extend .swiper-next;
        &::after{
            background: url(../img/banner-next.png) no-repeat center center /100%;
        }
    }
    .swiper-pagination-bullet-active{
        background:$Hover_color ;
    }
    .auto{
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
    }
    .banner-con{
        text-align: right;
        padding-top: 10%;
        h3{
            font-size: 2.6rem;
            color:#ffffff;
        }
        p{
            font-size:1.5rem;
            color:#ffffff;
        }
        a{
            width: 6rem;
            height:2.2rem;
            line-height: 2rem;
            text-align: center;
            font-size: .85rem;
            color:#ffffff;
            border:2px #ffffff  solid;
            border-radius: .8rem;
            float: right;
        }
    }
}

.homeItem1{
    width: 100%;
    background: #fdfdfd url(../img/home/homeItem1.png) no-repeat center center;
    padding: 50px 0;
    .col-md-3{
        padding:0;
    }
    .list-item{
        background: url(../img/home/homeItem1-item.png) no-repeat center center /100%;
        padding:1.1rem .95rem 1rem .9rem;
        img{
            transition: all .3s ease;
        }
        @include hover-focus {
            img{
                transform: scale3d(1.1,1.1,1.1);
            }
        }
    }
    .list-con{
        text-align: center;
        h4{
            color:#454545;
            font-weight: 500;
            font-size: 1.2rem;
            margin-bottom: .85rem;
        }
        em{
            padding: .15rem .8rem;
            font-size:.86rem;
            color:#ffffff;
            background: #606060;
            border-radius: .45rem;
            margin-left: .4rem;
            font-style:normal;
        }
        p{
            font-size: 1rem;
            color:#686868;
        }
    }
}
.homeItem2{
        width: 100%;
        background:#ff9501;
        &-video{
            width: 100%;
            height: 17.5rem;
            border: 0.15rem #ffffff solid;
            border-radius: 1rem;
            background: #ffffff;
            // box-shadow: 0px 2px 5px #999999;
        }
        &-con{
            h3{
                font-size: 2rem;
                color:#ffffff;
                margin: 0;
                padding-top: .5rem;
            }
            h4{
                font-size: 1.8rem;
                color:#ffffff;
                opacity: .6;
                font-weight: 500;
            }
            a{
                height: 2.2rem;
                line-height: 2.1rem;
                padding:0 .75rem;
                border:0.15rem #ffffff solid;
                font-size: 1rem;
                color:#ffffff;
                border-radius: .85rem;
                display: inline-block;
                margin:0 .5rem .8rem 0;
                transition: all .3s;
                @include hover-focus{
                    background: #ffffff;
                    color:$Hover_color;
                }
            }
        }
        &-list{
            p{
                font-size: 1.1rem;
                color:#ffffff;
                padding-bottom: .5rem;
                opacity: .8;
                transition: all .3s;
                margin: 0;
                &.on{
                    opacity: 1;
                }
            }
        }
}

.homeItem3{
    .title{
        text-align: left;
        h3{
            color:$Color_66;
            margin-bottom:.5rem;
        }
        h4{
            color:$Color_a3;
        }
    }
    .tabs{
        width: 310px;
        // overflow: hidden;
        ul{
            display: flex;
            flex-wrap:wrap;
            padding: 0  ;
        }
        li{
            list-style: none;
            width: 125px;
            height: 125px;
            border-radius: 2rem;
            background: #ffffff;
            box-shadow: 0px 2px 5px #bbbbbb;
            text-align: center;
            transition: all .3s;
            position: relative;
            cursor: pointer;
            float: left;
            margin: 0 1rem 1rem 0;
            p{
                font-size: .8rem;
                color:$Color_66;
                padding-top:10px;
                position: absolute;
                margin-bottom: 0;
                left: 0;
                bottom:20px;
                width: 100%;
                line-height: 20px;
                transition: all .3s;
            }
            span{
                width: 100%;
                height: 60px;
                display: block;
                transition: all .3s;
                position: absolute;
                &.icon1{
                    width: 33px;
                    height: 33px;
                    top: 25px;
                    left: 45px;
                    background: url(../img/icon.png) no-repeat 0 -28px;
                }
                &.icon2{
                    width: 34px;
                    height: 34px;
                    top: 24px;
                    left: 44px;
                    background: url(../img/icon.png) no-repeat 0 -63px;
                }
                &.icon3{
                    width: 40px;
                    height: 24px;
                    top: 30px;
                    left: 42px;
                    background: url(../img/icon.png) no-repeat 0 -101px;
                }
                &.icon4{
                    width: 24px;
                    height: 38px;
                    top: 22px;
                    left: 50px;
                    background: url(../img/icon.png) no-repeat 0 -127px;
                }
            }
            &.active{
                background: #ff6900;
                p{
                    color:#ffffff;
                }
                span{
                    &.icon1{
                        width: 33px;
                        height: 33px;
                        top: 25px;
                        left: 45px;
                        background: url(../img/icon.png) no-repeat -34px -28px;
                    }
                    &.icon2{
                        width: 34px;
                        height: 34px;
                        top: 24px;
                        left: 44px;
                        background: url(../img/icon.png) no-repeat -34px -63px;
                    }
                    &.icon3{
                        width: 40px;
                        height: 24px;
                        top: 30px;
                        left: 42px;
                        background: url(../img/icon.png) no-repeat -41px -101px;
                    }
                    &.icon4{
                        width: 24px;
                        height: 38px;
                        top: 22px;
                        left: 50px;
                        background: url(../img/icon.png) no-repeat -26px -127px;
                    }
                }
            }
        }
    }
}

.homeItem4{
    width: 100%;
    height: 19rem;
    background: url(../img/home/homeItem4.jpg) no-repeat center center /cover;
    position: relative;
    .back{
        width: 100%;
        height: 100%;
        background: #ff4800;
        transition:  all .4s;
        position: absolute;
        left: 0;
        top: 0;
        opacity: .7;
    }
    .con{
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 5;
        h3{
            margin: 0;
            color:#ffffff;
            font-weight: 600;
        }
        h4{
            font-size: 1.2rem;
            color:#ffffff;
            opacity: 0.6;
            font-weight: 200;
            padding-top: .3rem;
        }
        p{
            color:#ffffff;
        }
        a{
            width: 7rem;
            height: 2.5rem;
            line-height: 2.5rem;
            border: .08rem #ffffff solid;
            border-radius: 1rem;
            text-align: center;
            font-size: 1rem;
            color:#ffffff;
            display: block;
            margin: 2rem auto 0 auto;
        }
    }
}

.homeItem5{
    width: 100%;
    &-main{
        width: 90%;
        margin: 0 auto;
    }
    &-item{
        width: 150px;
        height: 150px;
        padding:8px 11px 13px 10px;
        background: url(../img/home/homeItem5-con.png) no-repeat center center;
        float: left;
        .skill-mask .skill-mask-con{
            -webkit-mask: url(../img/svg/skill-mask2.svg) center/contain no-repeat;
            mask: url(../img/svg/skill-mask2.svg) center/contain no-repeat;
            position: relative;
            
        }
    }
    .con{
        min-height: 150px;
        display: flex;
        align-items: center;
        h3{
            font-size: 1.4rem;
            color:$Color_66;
            padding-bottom: .1rem;
            margin: 0;
        }
        p{
            font-size: 1.1rem;
            color:$Color_66;
        }
    }
    .swiper-button-prev{
        left: 0;
        @extend .swiper-prev;
    }
    .swiper-button-next{
        right: 0;
        @extend .swiper-next;
    }
    
}

.homeItem6{
    width: 100%;
    background: #f1f4f7;
    .title{
        width: 100%;
        text-align: center;
        padding-bottom: 1.5rem;
        h3,h4{
            color:$Color_66;
            margin: .5rem;
        }
    }
    a{
        width: 100%;
        display: block;
        box-shadow:0px 2px 5px #bbbbbb;
        border-radius: 1rem;
        transition: all .3s;
        overflow: hidden;
        @include hover-focus{
            box-shadow:0px 2px 8px #999999;
            img{
                transform: scale3d(1.1,1.1,1.1);
            }
        }
    }
    .list-img{
        width: 100%;
        overflow: hidden;
        img{
            width: 100%;
            transition: all .3s;
        }
    }
    .list-con{
        width: 100%;
        text-align: center;
        background: #ffffff;
        h3{
            font-size: 1.2rem;
            color:$Color_66;
            padding: 1rem 0 .5rem;
            margin: 0;
        }
        p{
            font-size: 1rem;
            color:#adadad;
            padding-bottom: 1.2rem;
            margin: 0;
        }
    } 
}

.homeItem7{
    width: 100%;
    background: #f8f8fa;
    .col{
        background: url(../img/home/homeIcon.png) no-repeat right center;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child{
            background: none;
        }
        img{
            filter: rgb(128,128,128) !important; 
            filter: grayscale(100%); 	
            transition: all .3s;
            &:hover{
                filter: grayscale(0%);
            }
        }
    }
}
.homeItem8{
    width: 100%;
    .title{
        width: 100%;
        position: relative;
        text-align: left;
        h3{
            color:$Color_66;
            margin: 0;
        }
        h4{
            color:$Color_a3;
            font-weight: 500;
        }
        .more{
            font-size: 14px;
            color:$Color_66;
            transition: all .3s;
            position: absolute;
            right: 0;
            top: 0;
            @include hover-focus{
                color:$Hover_color;
                span{
                    background: url(../img/icon.png) no-repeat -22px -19px;
                }
            }
            span{
                width: 22px;
                height: 8px;
                display: inline-block;
                margin-left: 8px;
                background: url(../img/icon.png) no-repeat 0 -19px;
                transition: all .3s;
            }
        }
    }
    .list{
        width: 100%;
        ul{
            margin: 0;
            padding: 0;
        }
        li{
            width: 100%;
            list-style: none;
            padding-bottom: 1rem;
            @include hover-focus{
                h3,h4{
                    color:$Hover_color;
                }
            }
            h3{
                transition: all .3s;
                font-size:.85rem;
                color:#6b6b6b;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            h4{
                font-size: 1.3rem;
                color:#c7c7c7;
                transition: all .3s;
                margin: 0;
                font-weight: 200;
            }
        }
    }
    .img{
        width: 100%;
        overflow: hidden;
        border-radius: 1rem;
        box-shadow:0px 2px 8px #999999;
        img{
            width: 100%;
        }
    }
    
}






































/* 媒体查询 */

@media (max-width: 990px) {
    .navMenu{
        padding-left: 0;
    }
    .navbar-brand{
        margin-right: 0;
    }
}
@media (max-width: 765px) {
    
    #footer{
        dl{
            margin-right: 2.8rem;
        }
        .row{
            margin: 0;
        }
    }
    .homeItem3{
        .tabs{
            margin: 0 auto;
        }
    }
    .homeItem5{
        .swiper-button-next, .swiper-button-prev{
            top: 30%;
        }
        .homeItem5-main{
            width: 100%;
        }
        .homeItem5-item{
            float: none;
            margin: 0 auto;
        }
    }

}
@media (max-width: 580px) {
    #footer{
        /* .row{
            display: block;
        } */
        .footer-WeChat{
            width: 100%;
        }
        .WeChat{
            float: right;
        }
        dl{
            margin-right: 1.8rem;
        }
    }


}


